:root {
    --font-mono: "Courier Prime", "SF Mono", "Fira Code", "Fira Mono",
    "Roboto Mono", monospace;
  --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
    --site-background-color: #0a192f;
    --site-font-color: #cdd2de;
    --link-color: #21b693;
    --main-heading-font-color: #eef0f8;
    --sub-heading-font-color: #cdd2de;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
  }

  html,
body {
  font-size: 1em;
  font-family: var(--font-sans);
  color: var(--site-font-color);
  background-color: var(--site-background-color);
  width: 100vw;
  height: 100svh;

}


h1{
    color: var(--main-heading-font-color);
    font-size: 2.2em;
}

h2{
    color: var(--sub-heading-font-color);
}

a {
    color: var(--link-color);
  }


  .app-header {
    padding: 20px;
  }

  .app-link-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .link-medium {
    font-size: 2.5em;
  }

  .app-text {
    margin: 20px;
    line-height: 1.5;
  }

  @media (max-width: 480px) {
    .app-header, .app-subheader, .app-intro {
      padding: 10px;
    }
  
    .app-text {
      font-size: 0.9em;
    }
  
    .app-link-icons {
      width: 40px;
      height: 40px;
    }
  }